import Link from "next/link"
import { useRouter } from "next/router"
import Image from "next/image"

export function CardImage({
  title,
  description,
  image_path,
  image_alt,
  image_blur,
  cta_button,
}) {
  const router = useRouter()

  if (!image_path) return null

  const titleSlug = title.toLowerCase().split(" ")[0]
  const cardId = `search-${titleSlug}`
  const link = `jobs?search=${title}`

  const onClick = () => {
    router.push(link)
  }
  return (
    <li
      className="usa-card flex-1 cursor-pointer select-none"
      onClick={onClick}
    >
      <div className="usa-card__container hover:shadow-md mx-0">
        <header className="usa-card__header">
          <h2 className="text-base text-dolBlue font-serif font-bold">
            <Link href={link} passHref>
              <a aria-describedby={cardId}>{title}</a>
            </Link>
          </h2>
        </header>
        <div className="usa-card__media">
          <div
            className="usa-card__img relative w-full"
            style={{ height: "250px" }}
          >
            <Image
              src={image_path}
              alt={image_alt}
              blurDataURL={image_blur}
              layout="fill"
              objectFit="cover"
              placeholder="blur"
              lazyBoundary="350px"
            />
          </div>
        </div>
        <div className="usa-card__body text-sm text-gray-800">
          <p>{description}</p>
        </div>
        <div className="usa-card__footer">
          <span
            id={cardId}
            aria-hidden={true}
            className="usa-button usa-button--outline text-sm px-2 py-2 w-full"
          >
            {cta_button}
          </span>
        </div>
      </div>
    </li>
  )
}
