import Link from "next/link"

export function LayoutBanner({
  heading,
  headingColor = "white",
  headingTw,
  description,
  descriptionTw,
  // descriptionColor = "[#9ed1eb]",
  link_to,
  button_link = "Button",
  // bg, Disabled, for some reason doesn't work in build
  headingOverride,
  gridContainer = true,
  gridContainerHeading = true,
  tw,
  children,
  ...rest
}) {
  return (
    <section
      className={`usa-section py-6 sm:py-10 bg-bannerBlue ${tw}`}
      {...rest}
    >
      <div
        className={`flex sm:items-center justify-between flex-col sm:flex-row ${
          gridContainerHeading ? "grid-container" : ""
        }`}
      >
        <div>
          <h2
            className={`text-3xl font-semibold text-${headingColor} font-sans ${headingTw}`}
          >
            {heading}
          </h2>
          <div
            style={{ color: "#9ed1eb" }}
            className={`text-md py-3 mr-8 ${descriptionTw}`}
          >
            <p>{description}</p>
          </div>
        </div>
        <Link href={link_to}>
          <a
            className={`usa-button usa-button--outline usa-button--inverse text-md sm:px-20 sm:w-auto py-2.5 my-4 w-full whitespace-nowrap`}
          >
            {button_link}
          </a>
        </Link>
      </div>
    </section>
  )
}
