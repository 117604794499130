import { LayoutBanner } from "components/Templates/LayoutBanner"
import { useTranslations } from "use-intl"

export function RepostedJobsBanner() {
  const t = useTranslations("Homepage.LayoutBanner")
  return (
    <LayoutBanner
      heading={t("heading")}
      description={t("description")}
      link_to={t("link_to")}
      button_link={t("button_link")}
    ></LayoutBanner>
  )
}
