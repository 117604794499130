import Image from "next/image"
import { GridContainer } from "@trussworks/react-uswds"

import { useTranslations } from "use-intl"

export function Hero() {
  const t = useTranslations("Homepage.Hero")
  return (
    <section aria-label="Introduction">
      <div
        style={{ position: "relative", width: "100%", height: "400px" }}
        className="bg-sky-200"
      >
        <Image
          priority={true} // above the fold
          src={t("src")}
          placeholder="blur"
          blurDataURL={t("blur")}
          objectFit="cover"
          layout="fill"
          alt="Vineyard."
        />
        <GridContainer>
          <div
            className="usa-hero__callout shadow-lg sm:rounded-md absolute left-0 sm:relative top-6"
            style={{
              maxWidth: "22rem",
            }}
          >
            <h1 className="usa-hero__heading">
              {t.rich("title", {
                regular: (children) => (
                  <span className="usa-hero__heading text-white">
                    {children}
                  </span>
                ),
                accent: (children) => (
                  <span
                    className="usa-hero__heading"
                    style={{ color: "#9ed1eb" }}
                  >
                    {children}
                  </span>
                ),
              })}
            </h1>
            <p className="text-white">{t("subtitle")}</p>
            <a
              href="#find-jobs"
              className="usa-button usa-button--outline usa-button--inverse"
              style={{ width: "100%", marginTop: 10 }}
            >
              {t("button")}
            </a>
          </div>
        </GridContainer>
      </div>
    </section>
  )
}
