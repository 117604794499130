import Head from "next/head"
import {
  Hero,
  PopularListings,
  RepostedJobsBanner,
  TopCards,
  TopLinks,
} from "components/Homepage"
import { useFindJobs } from "components/JobSearch"
import { fetchTranslations } from "utils"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import { resetArchiveSlice, resetJobsSlice } from "state"
import { useTranslations } from "use-intl"
import { generateMetaTags } from "utils/generateMetaTags"

export default function Home() {
  const meta_title = "meta.title"
  const meta_description = "meta.description"
  const t = useTranslations("Homepage")
  const { FindJobs } = useFindJobs({
    which: "homepage",
    showHeading: true,
    tw: "border-t-4 border-green-800 pb-16 pt-10",
  })
  const dispatch = useDispatch()

  // on mount, reset search form storage (redux)
  useEffect(() => {
    dispatch(resetJobsSlice())
    dispatch(resetArchiveSlice())
  }, [dispatch])

  return (
    <div>
      <Head>
        <title>{t(meta_title)} | SeasonalJobs.dol.gov</title>
        <meta name="description" content={t(meta_title)} />
        {generateMetaTags({
          title: t(meta_title),
          description: t(meta_description),
          imageTitle: "SeasonalJobs.dol.gov",
          imageDescription: t(meta_description),
        })}
      </Head>
      <main id="main-content">
        <Hero />
        {FindJobs}
        <PopularListings />
        <RepostedJobsBanner />
        <TopCards />
        <TopLinks />
      </main>
    </div>
  )
}

export async function getServerSideProps({ locale }) {
  const homepage = await fetchTranslations({ locale, title: "Homepage" })
  const findJobs = await fetchTranslations({ locale, title: "FindJobs" })

  return {
    props: {
      messages: {
        // ...require(`../messages/hero/${locale}.json`), // local testing syntax
        ...homepage,
        ...findJobs,
      },
    },
  }
}
