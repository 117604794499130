import { useTranslations } from "use-intl"
import { CardImage } from "./CardImage"
import { Section } from "../Templates"

export function TopCards() {
  const ta = useTranslations("Homepage.TopCards.Agricultural")
  const tn = useTranslations("Homepage.TopCards.Non-Agricultural")

  return (
    <>
      <Section heading={ta("heading")} tw="pt-10">
        <ul className="flex flex-col md:flex-row md:space-x-4 mt-6">
          {[1, 2, 3].map((cardNo) => (
            <CardImage
              key={`agricultural-${cardNo}`}
              title={ta(`Card${cardNo}.title`)}
              description={ta(`Card${cardNo}.description`)}
              image_path={ta(`Card${cardNo}.image_path`)}
              image_blur={ta(`Card${cardNo}.image_blur`)}
              image_alt={ta(`Card${cardNo}.image_alt`)}
              cta_button={ta("cta_button")}
            />
          ))}
        </ul>
      </Section>
      <Section heading={tn("heading")}>
        <ul className="flex flex-col md:flex-row md:space-x-4 mt-6">
          {[1, 2, 3].map((cardNo) => (
            <CardImage
              key={`non-agricultural-${cardNo}`}
              title={tn(`Card${cardNo}.title`)}
              description={tn(`Card${cardNo}.description`)}
              image_path={tn(`Card${cardNo}.image_path`)}
              image_blur={tn(`Card${cardNo}.image_blur`)}
              image_alt={tn(`Card${cardNo}.image_alt`)}
              cta_button={tn("cta_button")}
            />
          ))}
        </ul>
      </Section>
    </>
  )
}
